<template>
    <div>
        <Panel :title="titleView" v-if="useDashboard"  style="margin:0px 1px; min-height: 480px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span>
                &nbsp;&nbsp;{{ filter.fromDate.text }}&nbsp;&nbsp;<DateBox v-model="filter.fromDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
                &nbsp;&nbsp;{{ filter.toDate.text }}  &nbsp;&nbsp;<DateBox v-model="filter.toDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
            </div>
            <Layout style="margin : 10px 0px;">
                <LayoutPanel region="east" style="width:25%; padding : 0px 10px" :bodyStyle="{borderRadius : '5px'}">
                    <Layout>
                        <LayoutPanel region="center" class="layout-full" :bodyStyle="{padding:'10px', border : 'none'}">
                            <div class="title">Trucking Order status</div>
                            <div class="content-chart">
                                <div ref="chartTruckingOrderStatus" class="chart-data" style="min-height: 450px; padding: 50px"></div>
                            </div>
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
                <LayoutPanel region="center" style="padding : 0px 10px" :bodyStyle="{borderRadius : '5px'}">
                    <Layout>
                        <LayoutPanel region="east"  class="layout-full" style="width:33%;" :bodyStyle="{padding:'10px', border:'none',}">
                            <div class="title">Traffic Monitoring stats</div>
                            <div class="content-traffic">
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.pickup}}</b> Pickup Order</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.pickupPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.pickupPercent" class="progress-pickup"></ProgressBar>
                                </div>
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.loading}}</b> Loading</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.loadingPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.loadingPercent" class="progress-loading"></ProgressBar>
                                </div>
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.finishLoading}}</b> Finish Loading</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.finishLoadingPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.finishLoadingPercent" class="progress-finish-loading"></ProgressBar>
                                </div>
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.arrived}}</b> Arrived</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.arrivedPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.arrivedPercent" class="progress-pickup"></ProgressBar>
                                </div>
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.unloading}}</b> Unloading</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.unloadingPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.unloadingPercent" class="progress-loading"></ProgressBar>
                                </div>
                                <div class="item-traffic">
                                    <div class="text-traffic">
                                        <div class="title-traffic"> <b>{{dataTrafficMonitoring.finishUnloading}}</b> Finish Unloading</div>
                                        <div class="percent-traffic">
                                            {{dataTrafficMonitoring.finishUnloadingPercent}} %
                                        </div>
                                    </div>
                                    <div style="clear:both;"></div>
                                    <ProgressBar :value="dataTrafficMonitoring.finishUnloadingPercent" class="progress-finish-loading"></ProgressBar>
                                </div>
                            </div>
                        </LayoutPanel>
                        <LayoutPanel region="center" class="layout-full" :bodyStyle="{padding:'10px', border:'none'}">
                            <div class="title">Shipment activity</div>
                            <div class="content-chart" >
                                <div ref="chartShipmentActual" class="chart-data" style="min-height: 450px;"></div>
                            </div>
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
            </Layout>

            <Layout style="margin : 10px 0px; min-height: 450px;">
                <LayoutPanel region="east" style="width:75%; padding : 0px 10px" :bodyStyle="{borderRadius : '5px', border:'none'}">
                    <Layout style="height: 60px; margin-bottom: 20px;">
                        <LayoutPanel region="center" class="layout-full" :bodyStyle="{borderRadius : '5px',  border:'none'}">
                            <Layout>
                                <LayoutPanel region="east" style="width:33.33%; padding: 0px 0px 0px 15px;" :bodyStyle="{borderRadius : '5px'}">
                                    <div class="content-card">
                                        <div class="item-card total-finish-pod">
                                            <div class="value-card"><b>{{dataDocument.totalPod}}</b></div>
                                            <div class="title-card">Finish POD</div>
                                        </div>
                                        <div class="img-card">
                                        </div>
                                    </div>
                                </LayoutPanel>
                                <LayoutPanel region="center" style="padding: 0px 8px;" :bodyStyle="{borderRadius : '5px'}">
                                    <div class="content-card ">
                                        <div class="item-card total-pending-pod">
                                            <div class="value-card"><b>{{dataDocument.totalPendingPod}}</b></div>
                                            <div class="title-card">Pending POD</div>
                                        </div>
                                        <div class="img-card">
                                        </div>
                                    </div>
                                </LayoutPanel>
                                <LayoutPanel region="west" style="width:33.33%; padding: 0px 15px 0px 0px;" :bodyStyle="{borderRadius : '5px'}">
                                    <div class="content-card">
                                        <div class="item-card total-shipment">
                                            <div class="value-card"><b>{{dataDocument.totalShipment}}</b></div>
                                            <div class="title-card">Total Shipment</div>
                                        </div>
                                        <div class="img-card">
                                        </div>
                                    </div>
                                </LayoutPanel>
                            </Layout>
                        </LayoutPanel>
                    </Layout>
                    <Layout style="height: 370px;">
                        <LayoutPanel region="center" class="layout-full" :bodyStyle="{borderRadius : '5px'}">
                            <div class="title-data">Unit Out Of Service</div>
                            <Panel style="width : 100%">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshServiceOrder()">{{ btnText.refresh }}</LinkButton>
                                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGrid.search"
                                        @search="onSearchFile()">
                                        <Addon>
                                            <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearchFile();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>
                                <DataGrid
                                        ref="dataGrid"
                                        :columnResizing="true"
                                        :border="false"
                                        :lazy="true"
                                        :loading="dataGrid.loading"                                    
                                        :data="dataGrid.rows"
                                        :pagination="true"
                                        :total="dataGrid.total"
                                        :pageSize="dataGrid.pageSize"
                                        :pageNumber="dataGrid.pageNumber"
                                        :pageLayout="dataGrid.pageLayout" 
                                        :footerData="dataGrid.footerData"
                                        :showFooter="true"
                                        @pageChange="onPageChange($event)"
                                        @sortChange="onSortChange($event)"
                                        @rowExpand="onRowExpand($event)"
                                        >
                                    
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn :expander="true" width="30"></GridColumn>

                                    <GridColumn field="service_order_no" title="Service Id" :sortable="true"></GridColumn>
                                    <GridColumn field="vehicle_desc" title="Vehicle Id" :sortable="true"></GridColumn>
                                    <GridColumn field="registered_date_desc" title="Registered Date" :sortable="true"></GridColumn>
                                    <GridColumn field="completion_date_desc" title="Completion Date" :sortable="true"></GridColumn>
                                    <GridColumn field="total_amount" title="Total Amount" :sortable="true" align="right"></GridColumn>
                                    <GridColumn field="workshop_desc" title="Workshop Id" :sortable="true"></GridColumn>
                                    <GridColumn field="service_type_desc" title="Service Type" :sortable="true"></GridColumn>
                                    <GridColumn field="service_status_desc" title="Service Status" :sortable="true"></GridColumn>
                                    <template slot="detail" slot-scope="scope">
                                        <div style="padding:2px 2px 2px 30px">
                                            <DataGrid :data="scope.row.subData" :showFooter="true" style="width: 80%;" :footerData="scope.row.subData.footerData">
                                                <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                                                <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                                                <GridColumn field="price" title="Price" width="80" align="right"></GridColumn>
                                                <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                                            </DataGrid>
                                        </div>
                                    </template>
                                </DataGrid>
                            </Panel>                            
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
                <LayoutPanel region="center" :bodyStyle="{borderRadius : '5px', border :'none'}">
                    <Layout>
                        <LayoutPanel region="center" class="layout-full" style="padding : 0px 10px 10px 10px" :bodyStyle="{borderRadius : '5px'}">
                            <div class="content-card">
                                <div class="item-card">
                                    <div class="value-card"><b>{{dataCost.totalCost}}</b></div>
                                    <div class="title-card">Total Cost</div>
                                </div>
                                <div class="img-card">
                                </div>
                            </div>
                        </LayoutPanel>
                        <LayoutPanel region="center" class="layout-full" style="padding :  0px 10px 10px 10px ;" :bodyStyle="{borderRadius : '5px'}">
                            <div class="content-card">
                                <div class="item-card">
                                    <div class="value-card"><b>{{dataCost.totalRevenue}}</b></div>
                                    <div class="title-card">Total Revenue</div>
                                </div>
                                <div class="img-card">
                                </div>
                            </div>
                        </LayoutPanel>
                        <LayoutPanel region="center" class="layout-full" style="padding :  0px 10px 10px 10px ;" :bodyStyle="{borderRadius : '5px'}">
                            <div class="title-data">Units</div>
                            <div class="content-data" >
                                <div class="item-units">
                                    <div class="title-units">Total Units</div>
                                    <div class="value-units"><b>{{dataUnits.totalUnits}}</b></div>
                                </div>

                                <div class="item-units" v-for="(val, idx) in dataUnits.detailUnits" v-bind:key="idx">
                                    <div class="title-units">{{val.type}}</div>
                                    <div class="value-units"><b>{{val.value}}</b></div>
                                </div>
                            </div>
                        </LayoutPanel>
                    </Layout>
                </LayoutPanel>
            </Layout>
        </Panel>    
    </div>
</template>

<script>
import GlobalUtil from '@/utils/GlobalUtil';
import IconUtil from '@/utils/IconUtil';
import InformationUtil from '@/utils/InformationUtil';

import AppService from '@/services/AppService';

// import chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import ModelUtil from '@/utils/ModelUtil';

am4core.useTheme(am4themes_animated);

export default {
    name: 'DashboardView',
    data() {
        return {
            titleView: 'Dashboard',
            urlAccess : 'dashboard',
            iconUtil : IconUtil,
            filter: {
                fromDate: {
                    text: 'From',
                    value: new Date()
                },
                toDate: {
                    text: 'To',
                    value: new Date()
                }
            },
            btnText: {
                refresh: InformationUtil.refreshText
            },
            useDashboard : false,

            dataChartShipmentActual : [],
            dataChartTruckingOrder : [],
            dataTrafficMonitoring : {
                pickup : 0,
                loading : 0,
                finishLoading : 0,
                arrived : 0,
                unloading : 0,
                finishUnloading : 0,
                pickupPercent : 0.0,
                loadingPercent : 0.0,
                finishLoadingPercent : 0.0,
                arrivedPercent : 0.0,
                unloadingPercent : 0.0,
                finishUnloadingPercent : 0.0,
            },
            dataCost : {
                totalCost : "IDR 0",
                totalRevenue : "IDR 0"
            },
            dataUnits : {
                totalUnits : 0,
                detailUnits : []
            },
            dataDocument : {
                totalShipment : 0,
                totalPendingPod : 0,
                totalPod : 0
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                // footer
                footerData : [
                    { completion_date_desc: "Total:", total_amount: 0 }
                ]
            },
        }
    },
    methods: {
        async onRefresh() {
            if(this.useDashboard)
            {
                await this.onLoadData();
            }
        },
        onLoadChartShipmentActual(){
            let chart = am4core.create(this.$refs.chartShipmentActual, am4charts.XYChart);
            chart.data = this.dataChartShipmentActual;
            let title = chart.titles.create();
            title.text = "Period : from "+this.$moment(this.filter.fromDate.value).format('LL')+" to " + this.$moment(this.filter.toDate.value).format('LL');
            
            title = chart.titles.create();
            title.text = "Shipment Activity by Type";
            title.fontSize = 12;
            title.fontWeight = "bold";

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "type";
            categoryAxis.renderer.labels.template.rotation = 320;

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Shipment Activity by Type";
            valueAxis.title.fontWeight = 800;

            var series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "value";
            series.dataFields.categoryX = "type";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.label.text =  "[bold]{valueY}[/]";
            bullet.label.fill = am4core.color("#000");
        },
        onLoadChartTruckingOrderStatus(){
            let chart = am4core.create(this.$refs.chartTruckingOrderStatus, am4charts.PieChart);
            chart.legend = new am4charts.Legend;
            chart.data = this.dataChartTruckingOrder;

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.colors.list = [
                am4core.color("#67b7dc"),
                am4core.color("#fdd400"),
            ];
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "type";
        },

        // get data
        async getShipmentActual(){
            AppService.post(this.urlAccess + '/shipmentActual', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataChartShipmentActual = response.data;
                    this.onLoadChartShipmentActual();
                }
            });
        },

        async getTruckingOrder(){
            AppService.post(this.urlAccess + '/truckingOrder', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataChartTruckingOrder = response.data;
                    this.onLoadChartTruckingOrderStatus();
                }
            });
        },

        async getTrafficMonitoring(){
            AppService.post(this.urlAccess + '/trafficMonitoring', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataTrafficMonitoring = ModelUtil.resetModel(this.dataTrafficMonitoring);
                    this.dataTrafficMonitoring = ModelUtil.mapModel(this.dataTrafficMonitoring, response.data);
                }
            });
        },

        async getCost(){
            AppService.post(this.urlAccess + '/cost', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataCost = response.data;
                }
            });
        },

        async getVehicle(){
            AppService.post(this.urlAccess + '/vehicle', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataUnits.totalUnits = response.data.totalVehicle;
                    this.dataUnits.detailUnits = response.data.details;
                }
            });
        },

        async getDocument(){
            AppService.post(this.urlAccess + '/document', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataDocument = response.data;
                }
            });
        },

        onRowExpand(row){
            if ( ! row.subData ){
                row.subData = JSON.parse(row.task_entries);
                row.subData.footerData = [
                    { price: "Sub Total:", amount: row.sub_total },
                    { price: "Total VAT:", amount: row.total_vat },
                    { price: "Total Amount:", amount: row.total_amount }
                ];
            }
        },

        async onRefreshServiceOrder(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            AppService.loadGrid(this.urlAccess + '/serviceOrder', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                        this.dataGrid.footerData[0].total_amount = data.total_cost;
                    }
                    this.dataGrid.loading = false;
                });
        },

        async onLoadData(){
            await this.$store.dispatch('getUser');
            this.useDashboard = this.$store.getters.user.use_dashboard == "0" ? false : true;
            if(this.useDashboard == 1){
                am4core.disposeAllCharts();
                await this.getShipmentActual();
                await this.getTruckingOrder();
                await this.getTrafficMonitoring();
                await this.getCost();

                this.getVehicle();
                this.getDocument();
                this.loadData();
            }
        },

        getParam(){
            let param = {
                startDate : this.$moment(this.filter.fromDate.value).format('YYYY-MM-DD'),
                endDate : this.$moment(this.filter.toDate.value).format('YYYY-MM-DD')
            }
            return param;
        },
    },
    async mounted() {
        await this.onLoadData();
    },
    created(){
        GlobalUtil.removeWindow();
    }
}
</script>

<style scoped>
.layout-full{
    height: 100%;
}

.title{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.content-chart{
    height: 90%;
    width: 100%;
}

.chart-data{
    height: 100%;
    width: 100%;
}

.content-traffic{
    padding: 10px 0px;
}
.item-traffic{
    margin-bottom: 15px;
}

.text-traffic{
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.title-traffic{
    width: 80%;
    float: left;
    font-size: 12px;
}

.percent-traffic{
    width: 20%;
    float: left;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

/* Card */
.item-card{
    padding : 10px 20px;
}

.value-card {
    font-size: 20px;
    font-weight: bold;
}

.title-card{
    font-size: 12px;
}

.title-data{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    padding : 10px;
}



</style>

<style>

.progressbar {
    height: 4px;
    border-radius: 3px;
}

.progress-pickup > .progressbar-value, .progress-pickup > .progressbar-value .progressbar-text {
    background-color: #2196f3 !important;
}

.progress-loading > .progressbar-value, .progress-loading > .progressbar-value .progressbar-text {
    background-color: #c77bd4 !important;
}

.progress-finish-loading > .progressbar-value, .progress-finish-loading > .progressbar-value .progressbar-text {
    background-color: #4caf50 !important;
}

/* units */
.item-units {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding: 10px 10px 15px 0px;
}

.title-units {
    font-size: 12px;
    padding: 0px 0px 0px 10px;
    width: 90%;
    float: left;
}

.value-units {
    width: 10%;
    float: left;
}

.content-units .item-units:last-child {
    border-bottom: none;
}

.total-shipment{
    background-color: #ffefa4;
    border-color: #ffd78a;
    color: #574802;
}

.total-pending-pod{
    background-color: #ffc9c9;
    border-color: #ffafbd;
    color: #ac1818;
}

.total-finish-pod{
    background-color: #bdedbc;
    border-color: #b4e8a8;
    color: #045702;
}
</style>